import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutUsPage from './pages/aboutUs/aboutUsPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import TimelinePage from './pages/timeline/TimelinePage';
import TeamsPage from './pages/teams/TeamsPage';
import AboutUltimatePage from './pages/aboutUltimate/aboutUltimatePage';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/home/HomePage';


const App = () => {
  return (
    <Router>
    <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/timeline" element={<TimelinePage />} />
        <Route path="/teams" element={<TeamsPage />} />
        <Route path="/aboutUltimate" element={<AboutUltimatePage />} />
        <Route path="/aboutUs" element={<AboutUsPage />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 pages */}
      </Routes>
    <Footer />
    </Router>
  );
}

export default App;
