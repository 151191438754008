import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="header">
            <Link to="/" className="logo-link">
                <img src="/images/turkeyUltimateLogo.jpg" alt="Logo" className="logo" />
            </Link>
            
            <nav className={`nav ${isOpen ? 'open' : ''}`} onClick={()=> setIsOpen(false)}>
                <ul>
                    <li><Link to="/aboutUs">Hakkımızda</Link></li>
                    <li><Link to="/aboutUltimate">Ultimate Nedir</Link></li>
                    <li><Link to="/timeline">Ülkemizde Ultimate</Link></li>
                    <li><Link to="/teams">Takımlarımız</Link></li>
                </ul>
            </nav>

            {isOpen && <div className="overlay" onClick={()=> setIsOpen(false)}></div>}

            <div className="hamburger" onClick={()=> setIsOpen(!isOpen)}> ☰ </div>

        </div>
    );
};

export default Header;